import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './index.scss';
import OrderDetail from './orderDetail'
import { Link } from "react-router-dom";
import ItemDetailPopover from '@/components/itemDetailPopover'

const getPageType = (quality) => {
    if (quality === "strange" || quality === "unusual_strange") {
        return 2
    } else {
        return 1
    }
}
const getInventoorySetting = (steamid, link) => {
    return (
        <div className="apikey-box-text" style={ {
            padding: '14px', width: '300px', backgroundColor: '#191919',
            boxShadow: `0px 0px 6px 1px #191919`
        } }>
            <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>采购人信息：</p>
            <p style={ { color: '#fff', fontSize: '14px' } }>SteamId：{ steamid }</p>
            
            <p style={ { color: '#fff', fontSize: '14px', } }>交易链接 </p>
            <h6 style={ { color: '#fff', fontSize: '12px', } }>{ link }</h6>
        </div>
    )
}

function OrderContainer(props) {
    const { orderData, currentNav, currentGame, getData } = props;
    const [ orderDetailView, setOrderDetailView ] = useState(false);
    const [ allPrice, setAllPrice ] = useState(0);
    const [ orderDetailData, setOrderDetailData ] = useState([]);
    const [ countDownList, setCountDownList ] = useState({});
    const [ loadingList, setLoadingList ] = useState(Array.from({ length: orderData.length }));
    const [ disabledList, setDisabledList ] = useState(Array.from({ length: orderData.length }, () => true));
    
    const {
        BASE_STEAM_IMG, orderStatusMessage, countDown, checkResult,
        calculationTargetTime, cancelOrderTime, obj2strUrl
    } = $tools
    let dataSource = [], columns;
    const handleCancelEvent = (t) => setDisabledList([ ...t.map(_ => ! _ || new Date(
        new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
    ).getTime() <= _) ])
    const handleOrderCountDown = (time, ctime, list) => {
        time.forEach(_ => {
            if (_[1] === " ") {
                list[_[0]] = ""
            } else if (_[1] === "暂无时间信息") {
                list[_[0]] = "暂无时间信息"
            } else {
                list[_[0]] = countDown({ time: _[1], type: 'order' })
            }
        })
        handleCancelEvent(ctime)
        setCountDownList({ ...list }) // 得浅克隆区分地址
    }
    const orderCountDown = (targData, timer) => {
        let list = {}
        const time = targData.map((_, index) => [ index, calculationTargetTime(_) ]);
        const ctime = targData.map(_ => cancelOrderTime(_));
        handleOrderCountDown(time, ctime, list)
        timer = setInterval(() => {
            handleOrderCountDown(time, ctime, list)
        }, 1000)
    }
    
    function handleLoading(index, val) {
        let list = [ ...loadingList ]
        list[index] = val
        setLoadingList(list)
    }
    
    const cancelOrder = async ({ orderNumber, appid, index, orderItemsStatus }) => {
        handleLoading(index, true)
        let result = await $api.order.saleOrderCancel({ orderNumber, appid, orderItemsStatus })
        handleLoading(index, false)
        if (checkResult(result)) {
            antdUI.message.success('取消中...')
            getData();
        }
    }
    
    useEffect(() => {
        let timer;
        orderCountDown(orderData, timer)
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const checkSaleOrderTime = (orderItem) => {
        const { offSendTime, duetime, orderItemsStatus, suspendedTime, orderType } = orderItem
        const tHTime = _ => _ * 60 * 60 * 1000;
        const curTime = new Date().getTime()
        const _duetime = new Date(duetime?.replace(/-/g, '/')).getTime()
        const _offSendTime = new Date(offSendTime?.replace(/-/g, '/')).getTime()
        const _suspendedTime = new Date(suspendedTime).getTime()
        switch (orderItemsStatus) {
            case 4:
                return curTime > (_duetime + tHTime(orderType === 'sale' ? 12 : 0.5) + 35 * 60 * 1000);
            case 6:
                return curTime > (_duetime + tHTime(orderType === 'sale' ? 12 : 0.5) + 5 * 60 * 1000);
            case 7:
                return curTime > (_offSendTime + tHTime(12) + 5 * 60 * 1000);
            case 8:
                return curTime > (_offSendTime + tHTime(24) + 5 * 60 * 1000);
            case 10:
                return curTime > (_offSendTime + tHTime(12) + 5 * 60 * 1000);
            case 11:
                if (! suspendedTime) return false
                return curTime > _suspendedTime
            default:
                return false
        }
    }
    
    const manualCheckSaleOrderBtnOnChange = async ({ appid, orderNumber }) => {
        const result = await $api.tradeoffer.manualCheckSaleOrder({ appid, orderNumber })
        if (checkResult(result)) {
            if (result?.data?.message) {
                antdUI.message.success(result?.data?.message)
            } else {
                antdUI.message.success('订单效验成功！')
            }
            getData();
        }
    }
    
    if (orderData[0] !== 'no') {
        switch (currentNav) {
            case 'onSale':
                columns = onSaleColumns;
                orderData.forEach((item, index) => {
                    item.market_item_detail = {
                        error: 0,
                        msg: {
                            floatvalue: item.floatvalue,
                            stickers: item.stickers || [],
                            paintseed: item.paintseed,
                            paintindex: item.paintindex,
                            customname: item.customname
                        }
                    }
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber }
                                 className="order-numbers">{ item.orderNumber }{ item.offerCode ? `-(${ item.offerCode })` : "" }</div>
                        ),
                        itemInfo: (
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: '730',
                                            curType: getPageType(item?.CsgoItem?.quality || item?.Dota2Item?.quality)
                                        }, '?')
                                    }
                                }
                                target='_blank'
                            >
                                <div className="orderTable item-info-box">
                                    <antdUI.Popover placement="rightTop"
                                                    content={ <ItemDetailPopover type="onSale" csData={ item }/> }>
                                        <div style={ favoritesItemStyle } className="favorites-item">
                                            <img
                                                style={ tabImg }
                                                src={ BASE_STEAM_IMG + item.icon_url }
                                                alt={ item.market_name }
                                            />
                                        </div>
                                    </antdUI.Popover>
                                    {
                                        Number(currentGame) === 730 ? <div className="on-shelf-item-name">
                                            <p className="item-name one-text-flow">{ item.market_name }</p>
                                            { item.floatvalue !== '0' &&
                                                <p className="floatval text-ov1">{ item.floatvalue && `磨损:${ item.floatvalue }` }</p> }
                                        </div> : <div className="on-shelf-item-name">
                                            <p className="item-name one-text-flow"
                                               style={ { margin: '10px 0' } }>{ item.market_name }</p>
                                        </div>
                                    }
                                </div>
                            </Link>
                        ),
                        sellingPrice: (
                            <div style={ { ...tabBox, fontSize: '16px', color: '#2DAF61' } }
                                 className="on-shelf-item-name">
                                ￥{ item.sellingPrice }
                            </div>
                        ),
                        buyer: <div className="flex jtyct">
                            <antdUI.Popover
                                placement="right"
                                content={ getInventoorySetting(item.buyerSteamid, item.transactionLink) }
                            >
                                <i className="iconfont icon-wenhao"></i>
                            </antdUI.Popover>
                        </div>,
                        duetime: <div
                            style={ { ...tabBox, textAlign: 'center' } }>{ item.duetime ? item.duetime : '暂无' }</div>,
                        orderItemsStatus: <>
                            {/* {item.orderItemsStatus} */ }
                            <div style={ { ...tabBox, width: '180px', margin: '0 auto' } }
                                 dangerouslySetInnerHTML={ {
                                     __html: orderStatusMessage({
                                         type: 'sale',
                                         status: item.orderItemsStatus,
                                         orderMsg: item.orderMessage,
                                         deliveryMethod: item.deliveryMethod,
                                         cancelStatus: item.cancelStatus,
                                         main: 'buyer',
                                     })
                                 } }
                            />
                            <div className="count-down">{
                                countDownList[index]
                            }</div>
                        </>,
                        operating: <div style={ { ...tabBox } }>
                            <antdUI.Button type="primary"
                                           disabled={ disabledList[index] || item.cancelStatus === 1 || item.cancelStatus === 2 || item.tradeofferid || item.orderType === 'buying' }
                                           loading={ loadingList[index] }
                                           style={ { marginRight: '4px' } } onClick={ () => cancelOrder({
                                orderNumber: item.orderNumber, appid: item.appid, index,
                                orderItemsStatus: item.orderItemsStatus,
                            }) }>取消订单
                            </antdUI.Button>
                            { checkSaleOrderTime(item) &&
                                <antdUI.Button type="primary"
                                               style={ { margin: '8px 4px 0 0' } }
                                               onClick={ () => manualCheckSaleOrderBtnOnChange(item) }>买家报价验证
                                </antdUI.Button> }
                        </div>
                    })
                })
                break;
            case 'intermediary':
                columns = intColumns;
                orderData.forEach((item, index) => {
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber } className="order-numbers">{ item.orderNumber }</div>
                        ),
                        itemTotal: (
                            <div style={ tabBox } className="on-shelf-item-name">
                                共{ item.itemTotal }件
                            </div>
                        ),
                        price: <div style={ { ...tabBox, color: '#2eb062' } }>￥<h1
                            style={ { ...tabH, color: '#2eb062' } }>{ item.totalPrice }</h1></div>,
                        buyer: <div style={ tabBox }>{ item.buyerid ? item?.User?.nickname : '暂无' }</div>,
                        handlingFee: <div style={ { ...tabBox, color: '#2eb062' } }>￥<h2
                            style={ { ...tabH, color: '#2eb062' } }>{ item.handlingFee }</h2></div>,
                        createTime: <div style={ tabBox }>{ item.create_time }</div>,
                        orderStatus: <div style={ { ...tabBox } }
                                          dangerouslySetInnerHTML={ {
                                              __html: orderStatusMessage({
                                                  type: 'int',
                                                  status: item.orderStatus
                                              })
                                          } }
                        />,
                        // orderLink: <div style={tabBox}>{`${window.location.host}/myRequest?orderLink=${item.orderNumber}`}</div>,
                        operating: <div style={ { ...tabBox, } }>
                            <antdUI.Button type="primary" style={ { marginRight: '4px' } } onClick={ () => {
                                setAllPrice(item.totalPrice)
                                setOrderDetailData(item)
                                setOrderDetailView(true)
                            } }>查看详情
                            </antdUI.Button>
                        </div>
                    })
                })
                break;
            case 'record':
                columns = onSaleRecordColumns;
                orderData.forEach((item, index) => {
                    item.market_item_detail = {
                        error: 0,
                        msg: {
                            floatvalue: item.floatvalue,
                            stickers: item.stickers || [],
                            paintseed: item.paintseed,
                            paintindex: item.paintindex,
                            customname: item.customname
                        }
                    }
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber }
                                 className="order-numbers">{ item.orderNumber }{ item.offerCode ? `-(${ item.offerCode })` : "" }</div>
                        ),
                        itemInfo: (
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: '730',
                                            curType: getPageType(item?.CsgoItem?.quality || item?.Dota2Item?.quality)
                                        }, '?')
                                    }
                                }
                                target='_blank'
                            >
                                <div className="orderTable item-info-box">
                                    <antdUI.Popover placement="rightTop"
                                                    content={ <ItemDetailPopover type="onSale" csData={ item }/> }>
                                        <div style={ favoritesItemStyle } className="favorites-item">
                                            <img style={ tabImg }
                                                 src={ BASE_STEAM_IMG + item.icon_url }
                                                 alt={ item.market_name }
                                            />
                                        </div>
                                    </antdUI.Popover>
                                    <div className="on-shelf-item-name">
                                        <p className="item-name one-text-flow">{ item.market_name }</p>
                                        { item.floatvalue !== '0' &&
                                            <p className="floatval text-ov1">{ item.floatvalue && `磨损:${ item.floatvalue }` }</p> }
                                    </div>
                                </div>
                            </Link>
                        ),
                        sellingPrice: (
                            <div style={ { ...tabBox, fontSize: '16px', color: '#2DAF61' } }
                                 className="on-shelf-item-name">
                                ￥{ item.sellingPrice }
                            </div>
                        ),
                        buyer: <div className="flex jtyct">
                            <antdUI.Popover
                                placement="right"
                                content={ getInventoorySetting(item.buyerSteamid, item.transactionLink) }
                            >
                                <i className="iconfont icon-wenhao"></i>
                            </antdUI.Popover>
                        </div>,
                        duetime: <div style={ tabBox }>
                            <p
                                style={ { textAlign: "center" } }>{ item.duetime ? item.duetime : '暂无' }
                            </p>
                            <p
                                style={ { textAlign: "center" } }>{ item.endtime ? item.endtime : '暂无' }
                            </p>
                        </div>,
                        orderItemsStatus: <div style={ { ...tabBox } }
                                               dangerouslySetInnerHTML={ {
                                                   __html: orderStatusMessage({
                                                       type: 'sale', main: 'buyer',
                                                       status: item.orderItemsStatus, orderMsg: item.orderMessage,
                                                   })
                                               } }
                        />
                    })
                })
                break;
            default:
                break;
        }
    }
    
    return (
        <div className="order-container">
            <antdUI.Table
                pagination={ false }
                dataSource={ dataSource }
                // locale={{ emptyText: '暂无数据' }}
                columns={ columns }
            >
            </antdUI.Table>
            { orderDetailView && <OrderDetail orderDetailView={ orderDetailView }
                                              setOrderDetailView={ setOrderDetailView }
                                              orderDetailData={ orderDetailData }
                                              allPrice={ allPrice }/> }
        </div>
    )
}

export default OrderContainer
let tabBox = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        flexWrap: 'wrap'
    },
    tabH = {
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgb(240, 147, 41)',
    },
    orderNumber = {
        color: '#000',
        textAlign: 'center'
    },
    favoritesItemStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        transform: 'scale(1.3)',
        backgroundImage: `url(${ require('@/images/items/item_bg.png') })`
    }, tabImg = {
        width: '65px'
    };
let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };

let onSaleColumns = [
    {
        title: <p style={ titleStyle }>订单号(验证码)</p>,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: 'itemInfo',
        key: 'itemInfo',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>订单金额</p>,
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
        render: text => text
    },
    {
        title: <p style={ { ...titleStyle, minWidth: "120px" } }>采购人</p>,
        dataIndex: 'buyer',
        key: 'buyer',
        render: text => text
    },
    {
        title: <p style={ { ...titleStyle, minWidth: "90px" } }>购买时间</p>,
        dataIndex: 'duetime',
        key: 'duetime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易状态</p>,
        dataIndex: 'orderItemsStatus',
        key: 'orderItemsStatus',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        render: text => text
    }
];
let intColumns = [
    {
        title: <p style={ titleStyle }>订单号</p>,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>数量</p>,
        dataIndex: 'itemTotal',
        key: 'itemTotal',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易价格</p>,
        dataIndex: 'price',
        key: 'price',
        render: text => text
    },
    {
        title: <p style={ { ...titleStyle, minWidth: "120px" } }>卖家昵称</p>,
        dataIndex: 'buyer',
        key: 'buyer',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>手续费</p>,
        dataIndex: 'handlingFee',
        key: 'handlingFee',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>创建时间</p>,
        dataIndex: 'createTime',
        key: 'createTime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易状态</p>,
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        render: text => text
    },
    // {
    //   title: <p style={titleStyle}>商品交易链接</p>,
    //   dataIndex: 'orderLink',
    //   key: 'orderLink',
    //   render: text => text
    // },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        render: text => text
    }
];

let onSaleRecordColumns = [
    {
        title: <p style={ titleStyle }>订单号(验证码)</p>,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: 'itemInfo',
        key: 'itemInfo',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>订单金额</p>,
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
        render: text => text
    },
    {
        title: <p style={ { ...titleStyle, minWidth: "120px" } }>采购人</p>,
        dataIndex: 'buyer',
        key: 'buyer',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>订单时间</p>,
        dataIndex: 'duetime',
        key: 'duetime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易状态</p>,
        dataIndex: 'orderItemsStatus',
        key: 'orderItemsStatus',
        render: text => text
    }
];
